.react-datepicker {
  font-family: 'Montserrat';
  margin-top: 3px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: none;
  margin-bottom: -3px;
}
.react-datepicker__day {
  position: relative;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.5rem;
  line-height: 1.5rem;
  height: 1.5rem;
}

.react-datepicker__day-names {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 2px;
}

.react-datepicker__month {
  margin: 0px 7px 9px 7px;
  padding-top: 0px;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range {
  background-color: rgba(225, 212, 241, 1);
  color: #333333;
  border-radius: 15px;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background: unset;
}

.react-datepicker__day--selected .calendar-day-wrapper,
.react-datepicker__day--selected:hover .calendar-day-wrapper,
.react-datepicker__day:hover .calendar-day-wrapper,
.react-datepicker__day--range-start .calendar-day-wrapper,
.react-datepicker__day--range-end .calendar-day-wrapper {
  background-color: #6829b8;
  border-radius: 15px;
  color: #fff;
}

.react-datepicker__day.react-datepicker__day--disabled:hover .calendar-day-wrapper {
  background-color: unset;
  color: #858585;
}

.react-datepicker__day.react-datepicker__day--in-range .calendar-day-wrapper {
  color: #333;
}

.react-datepicker__day.react-datepicker__day--in-range .calendar-day-wrapper:hover {
  color: #fff;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0px;
}

.react-datepicker__navigation {
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  display: inline-block;
  background-size: 11px 10px;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-position: center;
  margin-top: -5px;
}

.react-datepicker__navigation:hover {
  background-size: 11px 10px;
  background-color: #cce6ea;
  border-radius: 15px;
}

.react-datepicker__navigation.react-datepicker__navigation--previous {
  background-image: url('../../shared/images/calendar-previous.svg');
}

.react-datepicker__navigation.react-datepicker__navigation--next {
  background-image: url('../../shared/images/calendar-next.svg');
  background-position-x: 7px;
}

.react-datepicker__day--outside-month {
  color: #858585;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #333;
  font-size: 0.75rem;
  line-height: 17px;
  text-transform: capitalize;
}

.react-datepicker__day--today {
  font-weight: normal;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.1rem;
}

.datepicker-range .react-datepicker__day--in-range::before,
.datepicker-range .react-datepicker__day--in-selecting-range::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #e1d4f1;
  z-index: 1;
}

.datepicker-range .react-datepicker__day--range-end.react-datepicker__day--range-start::before {
  background: unset;
}

.datepicker-range .react-datepicker__day-name,
.datepicker-range .react-datepicker__day,
.datepicker-range .react-datepicker__time-name {
  margin: 0rem;
  line-height: 24px;
  width: 24px;
  height: 24px;
  padding-left: 1.5px;
  padding-right: 1.5px;
  box-sizing: content-box;
}

.datepicker-range .react-datepicker__day .calendar-day-today-border {
  display: none;
}

.react-datepicker__day--today:not(.react-datepicker__day--selecteda):not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range)
  .calendar-day-today-border {
  display: block;
  border: 1px solid #6829b8;
  border-radius: 15px;
  width: 22px;
  height: 22px;
  position: absolute;
}

.datepicker-range .react-datepicker__day--range-start .calendar-day-wrapper,
.datepicker-range .react-datepicker__day--range-end .calendar-day-wrapper,
.datepicker-range .react-datepicker__day--selecting-range-start .calendar-day-wrapper,
.datepicker-range .calendar-day-wrapper:hover {
  background-color: #6829b8;
  color: #ffffff;
  z-index: 2;
  position: relative;
  border-radius: 15px;
}

.datepicker-range .calendar-day {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  z-index: 100;
}

.datepicker-range .react-datepicker__week {
  margin: 1px 0px;
}

.datepicker-range .react-datepicker__day--in-range.react-datepicker__day--today,
.datepicker-range .react-datepicker__day--in-selecting-range.react-datepicker__day--today {
  border: none;
}

.datepicker-range .react-datepicker__day--range-end::before,
.datepicker-range .react-datepicker__day--in-selecting-range:last-child::before,
.datepicker-range .react-datepicker__day--in-range:last-child::before,
.react-datepicker__day.react-datepicker__day--in-selecting-range:hover::before {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 97%;
}

.datepicker-range .react-datepicker__day--range-start::before,
.datepicker-range .react-datepicker__day--selecting-range-start::before,
.datepicker-range .react-datepicker__day--in-selecting-range:first-child::before,
.datepicker-range .react-datepicker__day--in-range:first-child::before {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-left: 3%;
  width: 97%;
}

.datepicker-range .react-datepicker__month {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.react-datepicker-wrapper input {
  text-overflow: ellipsis;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #858585;
}

.react-datepicker__day-name {
  color: #333;
}
